$(".carousel-slick").slick({
	arrows: false,
	autoplay: true,
	// autoplay: false,
	autoplaySpeed: 2200,
	mobileFirst: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 760,
			settings: {
				slidesToShow: 2,
			},
		},

		{
			breakpoint: 992,
			settings: {
				slidesToShow: 3,
			},
		},
	],
});
